<template>
  <div>
    <th-card class="h-100">
      <template slot="header">
        <h6 v-html="$translation.t('Personal information')"></h6>
        <p
          class="body2"
          v-html="
            $translation.t(
              'Some info may be visible to other using TermsHub document.'
            )
          "
        ></p>
      </template>
      <template slot="body">
        <div class="profile-details__item th-py-16">
          <h6
            class="th-mb-0 th-mr-12 body2 text-neutral-dark profile-details__item-title"
          >
            {{ $translation.t("Photo") }}
          </h6>
          <avatar size="40" />
        </div>
        <div class="profile-details__item th-py-16 cursor-pointer">
          <h6
            class="th-mb-0 th-mr-12 body2 text-neutral-dark profile-details__item-title"
            @click="editEmail = true"
          >
            {{ $translation.t("Email") }}
          </h6>
          <h6 class="th-m-0 body1 flex-grow-1" @click="editEmail = true">
            {{ email }}
            <th-badge
              color="primary"
              :solid="false"
              class="body2 th-mt-8"
              v-html="
                $translation.t(
                  'Check your email in order to verify you email address'
                )
              "
              v-if="verificationSent"
            ></th-badge>
          </h6>
          <div v-if="!emailVerified && !verificationSent">
            <v-btn color="primary" @click="verifyEmail" :loading="verifying">
              {{ $translation.t("Verify email") }}
            </v-btn>
          </div>
          <div class="d-none d-md-block" @click="editEmail = true">
            <v-icon size="24" color="secondaryDark"> mdi-chevron-right </v-icon>
          </div>
        </div>
        <div
          class="profile-details__item th-py-16 cursor-pointer"
          @click="editPassword = true"
        >
          <h6
            class="th-mb-0 th-mr-12 body2 text-neutral-dark profile-details__item-title"
          >
            {{ $translation.t("Password") }}
          </h6>
          <h6 class="th-m-0 body1 flex-grow-1">**********</h6>
          <div class="d-none d-md-block">
            <v-icon size="24" color="secondaryDark"> mdi-chevron-right </v-icon>
          </div>
        </div>
      </template>
    </th-card>
    <v-dialog
      v-model="editPassword"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-16 th-p-40-md">
        <template slot="body">
          <password-change-form @success="editPassword = false">
            <h5
              class="th-mb-24"
              v-html="$translation.t('Password change')"
            ></h5>
          </password-change-form>
        </template>
      </th-card>
    </v-dialog>
    <v-dialog
      v-model="editEmail"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-16 th-p-40-md">
        <template slot="body">
          <email-update-form @success="editEmail = false">
            <h5 class="th-mb-24" v-html="$translation.t('Email change')"></h5>
          </email-update-form>
        </template>
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import ThCard from "@/components/GeneralComponents/ThCard";
import PasswordChangeForm from "@/components/Client/PasswordChangeForm";
import EmailUpdateForm from "@/components/Client/EmailUpdateForm";
import { mapState } from "vuex";
import Avatar from "@/components/Client/Avatar";
import ThBadge from "@/components/GeneralComponents/ThBadge";

export default {
  name: "PersonalInformation",
  components: { ThBadge, Avatar, EmailUpdateForm, PasswordChangeForm, ThCard },
  data: () => ({
    editPassword: false,
    editEmail: false,
    verificationSent: false,
    verifying: false,
  }),
  computed: {
    ...mapState({
      email: (state) => state.client.email,
      emailVerified: (state) => state.client.emailVerified,
    }),
  },
  methods: {
    async verifyEmail() {
      this.verifying = true;

      // await this.$axios.post("/api/v1/user/email-verify");

      this.verificationSent = true;
      this.verifying = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-details {
  &__item {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $neutral-mid;
    }
  }

  @include media-breakpoint-up(md) {
    &__item-title {
      min-width: 100px;
    }
  }
}
</style>
