<template>
  <div>
    <template v-if="checkEmail">
      <h5
        class="th-mb-24 text-center"
        v-html="$translation.t('Great, check your inbox!')"
      ></h5>
      <p class="text-center">
        {{
          $translation.t(
            "We've just sent you a link to {email} to confirm your new email address. Click on the link in the email and follow the instructions",
            { email: newEmail }
          )
        }}
      </p>
    </template>
    <div v-else>
      <slot />
      <v-form ref="emailChange">
        <v-text-field
          :label="$translation.t('New Email')"
          :rules="[rules.required, rules.email]"
          outlined
          type="email"
          id="newEmail"
          name="newEmail"
          v-model="newEmail"
          class="mb-3"
          hide-details="auto"
        ></v-text-field>
        <p v-if="error" class="text-danger th-my-12">{{ error }}</p>
        <div class="text-right">
          <v-btn
            color="primary"
            :block="$vuetify.breakpoint.smAndDown"
            large
            @click="emailChange"
            :loading="changing"
          >
            {{ $translation.t("Change Email") }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";

export default {
  name: "EmailUpdateForm",
  data: () => ({
    rules,
    newEmail: "",
    checkEmail: false,
    changing: false,
    error: "",
    errorInputs: [],
  }),
  methods: {
    async emailChange() {
      if (!this.$refs.emailChange.validate()) return;

      this.changing = true;

      try {
        await this.$axios.post("/api/v1/user/email-update", {
          email: this.newEmail,
        });

        this.checkEmail = true;
      } catch (e) {
        this.error = e.response.data.message;
        this.errorInputs = e.response.data.inputs || [];
        this.$log.notify(e);
      }

      this.changing = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
