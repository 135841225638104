<template>
  <v-avatar color="primary" :size="size">
    <v-img v-if="avatar" :src="avatar"></v-img>
    <v-icon v-else dark :size="size * 0.7"> mdi-account </v-icon>
  </v-avatar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Avatar",
  props: {
    size: {
      type: [String, Number],
      default: 32,
    },
  },
  data: () => ({ avatarSrc: null, loadingAvatar: false }),
  computed: {
    ...mapState({
      avatar: (state) => state.client.avatar,
    }),
  },
};
</script>

<style scoped></style>
