var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('th-card',[_c('h6',{staticClass:"th-mb-24 th-mr-12 profile-details__item-title",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Delete your account'))}}),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-md-center cursor-pointer"},[_c('div',{staticClass:"flex-grow-1"},[_c('p',{staticClass:"caption text-neutral-dark",domProps:{"innerHTML":_vm._s(
            _vm.$translation.t(
              'Once you delete an account, there is no going back. Please be certain'
            )
          )}})]),_c('div',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.deleteAccountConfirmation = true}}},[_vm._v(" "+_vm._s(_vm.$translation.t("Delete"))+" ")])],1)])]),_c('v-dialog',{attrs:{"max-width":"540","transition":"dialog-bottom-transition"},model:{value:(_vm.deleteAccountConfirmation),callback:function ($$v) {_vm.deleteAccountConfirmation=$$v},expression:"deleteAccountConfirmation"}},[_c('th-card',{attrs:{"body-classes":"th-p-40"}},[_c('template',{slot:"body"},[_c('h5',{staticClass:"th-mb-24",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Delete account?'))}}),_c('div',[_c('p',{staticClass:"body2 th-mb-16"},[_vm._v(" "+_vm._s(_vm.$translation.t( "This action cannot be undone. This will permanently delete account including, projects, documents, cookie consent banners, personal information subscriptions." ))+" ")]),_c('p',{staticClass:"body2 th-mb-16",domProps:{"innerHTML":_vm._s(
              _vm.$translation.t('Please type {phrase} to confirm', {
                phrase:
                  '<strong><i>&#8220;' +
                  _vm.requiredPhrase +
                  '&#8221;</i></strong>',
              })
            )}}),_c('div',[_c('v-text-field',{attrs:{"block":"","outlined":"","placeholder":_vm.requiredPhrase,"hide-details":"auto"},model:{value:(_vm.phrase),callback:function ($$v) {_vm.phrase=$$v},expression:"phrase"}}),_c('v-checkbox',{attrs:{"label":_vm.$translation.t(
                  'I understand the consequences, delete this account'
                ),"rules":[_vm.rules.required]},model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}})],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.deletingAccount},on:{"click":function($event){_vm.deleteAccountConfirmation = false}}},[_vm._v(" "+_vm._s(_vm.$translation.t("Cancel"))+" ")]),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.deletingAccount,"disabled":_vm.phrase !== _vm.requiredPhrase || !_vm.agree,"large":""},on:{"click":_vm.deleteAccount}},[_vm._v(" "+_vm._s(_vm.$translation.t("Delete Account"))+" ")])],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }