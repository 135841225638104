<template>
  <div class="settings th-mt-32">
    <div class="row">
      <div class="col-12 col-lg-6">
        <personal-information class="h-100" />
      </div>
      <div class="col-12 col-lg-6">
        <email-communications class="h-100" />
      </div>
      <div class="col-12 th-mt-24">
        <account-information />
      </div>
    </div>
  </div>
</template>

<script>
import AccountInformation from "../components/Client/AccountInformation";
import EmailCommunications from "../components/Client/EmailCommunications";
import PersonalInformation from "../components/Client/PersonalInformation";

export default {
  name: "Settings",
  components: {
    PersonalInformation,
    AccountInformation,
    EmailCommunications,
  },
};
</script>
