<template>
  <div>
    <th-card class="h-100">
      <template slot="header">
        <h6 v-html="$translation.t('Email Communications')"></h6>
        <p
          class="body2"
          v-html="
            $translation.t('Control what information you want to get from us')
          "
        ></p>
      </template>
      <template slot="body">
        <div
          class="d-flex flex-column flex-md-row align-items-md-center th-py-16"
        >
          <div class="flex-grow-1 max-w-450-md">
            <h6
              class="th-mb-8 th-mr-12 subtitle2 profile-details__item-title"
              v-html="$translation.t('Transaction emails')"
            ></h6>
            <p
              class="caption text-neutral-dark"
              v-html="
                $translation.t(
                  'These are the main emails. Transactional email is triggered by events, interactions you make by using TermsHub. You might receive payment confirmations, our policy updates, etc.'
                )
              "
            ></p>
          </div>
          <div
            class="d-flex align-items-center th-mx-24-md th-my-12 th-my-0-md flex-grow-1"
          >
            <img
              :src="require('@/assets/images/statuses/check-success.svg')"
              height="18"
              alt="Info"
              class="th-mr-8"
            />
            <span class="body2" v-html="$translation.t('Enabled')"></span>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-md-row align-items-md-center th-py-16 cursor-pointer"
          @click="manageNewsletter = true"
        >
          <div class="flex-grow-1 max-w-450-md">
            <h6
              class="th-mb-8 th-mr-12 subtitle2 profile-details__item-title"
              v-html="$translation.t('Newsletter')"
            ></h6>
            <p
              class="caption text-neutral-dark"
              v-html="
                $translation.t(
                  'Find out first about TermsHub newest features. Stay up to date with latest regulations which might impact you business'
                )
              "
            ></p>
          </div>
          <div
            class="d-flex align-items-center th-mx-24-md th-my-12 th-my-0-md flex-grow-1"
          >
            <img
              :src="iconByStatus(subscribedToNewsletter)"
              :alt="
                $translation.t(subscribedToNewsletter ? 'Enabled' : 'Disabled')
              "
              width="18"
              class="th-mr-8"
            />
            <span
              class="body2"
              v-html="
                $translation.t(subscribedToNewsletter ? 'Enabled' : 'Disabled')
              "
            ></span>
          </div>
          <div class="d-none d-md-block">
            <v-icon size="24" color="secondaryDark"> mdi-chevron-right </v-icon>
          </div>
        </div>
      </template>
    </th-card>
    <v-dialog
      v-model="manageNewsletter"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-40">
        <template slot="body">
          <h5
            class="th-mb-24"
            v-html="$translation.t('Update newsletter preferences')"
          ></h5>
          <div
            class="d-flex flex-column flex-md-row align-items-md-center th-mb-24"
          >
            <v-switch v-model="newsletterStatus" color="primary" />
            <h6
              class="subtitle1"
              v-html="
                $translation.t('I want to receive email update from TermsHub')
              "
            ></h6>
          </div>
          <div class="text-right">
            <v-btn
              color="primary"
              @click="updateNewsletterPreferences"
              :loading="newsletterUpdating"
              large
            >
              {{ $translation.t("Update preferences") }}
            </v-btn>
          </div>
        </template>
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import { iconByStatus } from "@/utils/statuses";
import { mapMutations, mapState } from "vuex";
import ThCard from "@/components/GeneralComponents/ThCard";

export default {
  name: "EmailCommunications",
  components: { ThCard },
  data: () => ({
    manageNewsletter: false,
    newsletterStatus: false,
    newsletterUpdating: false,
  }),
  created() {
    this.newsletterStatus = this.subscribedToNewsletter;
  },
  computed: {
    ...mapState({
      subscribedToNewsletter: (state) => state.client.subscribed_to_newsletter,
    }),
  },
  methods: {
    iconByStatus,
    async updateNewsletterPreferences() {
      this.newsletterUpdating = true;
      try {
        await this.$axios.put("/api/v1/user/preferences", {
          subscribed_to_newsletter: this.newsletterStatus,
        });

        this.UPDATE_SUBSCRIBED_TO_NEWSLETTER(this.newsletterStatus);
      } catch (e) {
        this.$log.notify(e);
      }
      this.newsletterUpdating = false;
      this.manageNewsletter = false;
    },
    ...mapMutations(["UPDATE_SUBSCRIBED_TO_NEWSLETTER"]),
  },
};
</script>

<style lang="scss" scoped></style>
