var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('th-card',{staticClass:"h-100"},[_c('template',{slot:"header"},[_c('h6',{domProps:{"innerHTML":_vm._s(_vm.$translation.t('Personal information'))}}),_c('p',{staticClass:"body2",domProps:{"innerHTML":_vm._s(
          _vm.$translation.t(
            'Some info may be visible to other using TermsHub document.'
          )
        )}})]),_c('template',{slot:"body"},[_c('div',{staticClass:"profile-details__item th-py-16"},[_c('h6',{staticClass:"th-mb-0 th-mr-12 body2 text-neutral-dark profile-details__item-title"},[_vm._v(" "+_vm._s(_vm.$translation.t("Photo"))+" ")]),_c('avatar',{attrs:{"size":"40"}})],1),_c('div',{staticClass:"profile-details__item th-py-16 cursor-pointer"},[_c('h6',{staticClass:"th-mb-0 th-mr-12 body2 text-neutral-dark profile-details__item-title",on:{"click":function($event){_vm.editEmail = true}}},[_vm._v(" "+_vm._s(_vm.$translation.t("Email"))+" ")]),_c('h6',{staticClass:"th-m-0 body1 flex-grow-1",on:{"click":function($event){_vm.editEmail = true}}},[_vm._v(" "+_vm._s(_vm.email)+" "),(_vm.verificationSent)?_c('th-badge',{staticClass:"body2 th-mt-8",attrs:{"color":"primary","solid":false},domProps:{"innerHTML":_vm._s(
              _vm.$translation.t(
                'Check your email in order to verify you email address'
              )
            )}}):_vm._e()],1),(!_vm.emailVerified && !_vm.verificationSent)?_c('div',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.verifying},on:{"click":_vm.verifyEmail}},[_vm._v(" "+_vm._s(_vm.$translation.t("Verify email"))+" ")])],1):_vm._e(),_c('div',{staticClass:"d-none d-md-block",on:{"click":function($event){_vm.editEmail = true}}},[_c('v-icon',{attrs:{"size":"24","color":"secondaryDark"}},[_vm._v(" mdi-chevron-right ")])],1)]),_c('div',{staticClass:"profile-details__item th-py-16 cursor-pointer",on:{"click":function($event){_vm.editPassword = true}}},[_c('h6',{staticClass:"th-mb-0 th-mr-12 body2 text-neutral-dark profile-details__item-title"},[_vm._v(" "+_vm._s(_vm.$translation.t("Password"))+" ")]),_c('h6',{staticClass:"th-m-0 body1 flex-grow-1"},[_vm._v("**********")]),_c('div',{staticClass:"d-none d-md-block"},[_c('v-icon',{attrs:{"size":"24","color":"secondaryDark"}},[_vm._v(" mdi-chevron-right ")])],1)])])],2),_c('v-dialog',{attrs:{"max-width":"540","transition":"dialog-bottom-transition"},model:{value:(_vm.editPassword),callback:function ($$v) {_vm.editPassword=$$v},expression:"editPassword"}},[_c('th-card',{attrs:{"body-classes":"th-p-16 th-p-40-md"}},[_c('template',{slot:"body"},[_c('password-change-form',{on:{"success":function($event){_vm.editPassword = false}}},[_c('h5',{staticClass:"th-mb-24",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Password change'))}})])],1)],2)],1),_c('v-dialog',{attrs:{"max-width":"540","transition":"dialog-bottom-transition"},model:{value:(_vm.editEmail),callback:function ($$v) {_vm.editEmail=$$v},expression:"editEmail"}},[_c('th-card',{attrs:{"body-classes":"th-p-16 th-p-40-md"}},[_c('template',{slot:"body"},[_c('email-update-form',{on:{"success":function($event){_vm.editEmail = false}}},[_c('h5',{staticClass:"th-mb-24",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Email change'))}})])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }