export default function (status) {
  switch (status) {
    case "confirmed":
      return "success";
    case "not published":
      return "error";
    default:
      return "default";
  }
}

export function iconByStatus(status) {
  try {
    // if (typeof status === "boolean") {
    //     return status
    //         ? require(`@/assets/images/statuses/check-success.svg`)
    //         : require(`@/assets/images/statuses/cross-danger.svg`);
    // }

    switch (status) {
      case true:
      case "true":
      case "success":
      case "active":
      case "included":
      case "available":
        return require(`@/assets/images/statuses/check-success.svg`);
      case "warning":
      case "incomplete":
      case "past_due":
      case "partially_included":
      case "pending":
        return require(`@/assets/images/statuses/check-yellow.svg`);
      case false:
      case "false":
      case "danger":
      case "inactive":
      case "canceled":
      case "cancelled":
      case "suspended":
      case "not_included":
        return require(`@/assets/images/statuses/cross-danger.svg`);
      case "incomplete_expired":
        return require(`@/assets/images/statuses/cross-neutral.svg`);
      default:
        return null;
    }
  } catch {
    return null;
  }
}
