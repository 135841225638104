<template>
  <div>
    <th-card>
      <h6
        class="th-mb-24 th-mr-12 profile-details__item-title"
        v-html="$translation.t('Delete your account')"
      ></h6>
      <div
        class="d-flex flex-column flex-md-row align-items-md-center cursor-pointer"
      >
        <div class="flex-grow-1">
          <p
            class="caption text-neutral-dark"
            v-html="
              $translation.t(
                'Once you delete an account, there is no going back. Please be certain'
              )
            "
          ></p>
        </div>
        <div>
          <v-btn color="error" text @click="deleteAccountConfirmation = true">
            {{ $translation.t("Delete") }}
          </v-btn>
        </div>
      </div>
    </th-card>
    <v-dialog
      v-model="deleteAccountConfirmation"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-40">
        <template slot="body">
          <h5 class="th-mb-24" v-html="$translation.t('Delete account?')"></h5>
          <div>
            <p class="body2 th-mb-16">
              {{
                $translation.t(
                  "This action cannot be undone. This will permanently delete account including, projects, documents, cookie consent banners, personal information subscriptions."
                )
              }}
            </p>
            <p
              class="body2 th-mb-16"
              v-html="
                $translation.t('Please type {phrase} to confirm', {
                  phrase:
                    '<strong><i>&#8220;' +
                    requiredPhrase +
                    '&#8221;</i></strong>',
                })
              "
            ></p>
            <div>
              <v-text-field
                block
                outlined
                v-model="phrase"
                :placeholder="requiredPhrase"
                hide-details="auto"
              />
              <v-checkbox
                :label="
                  $translation.t(
                    'I understand the consequences, delete this account'
                  )
                "
                v-model="agree"
                :rules="[rules.required]"
              />
            </div>
            <div class="text-right">
              <v-btn
                text
                color="primary"
                @click="deleteAccountConfirmation = false"
                :disabled="deletingAccount"
              >
                {{ $translation.t("Cancel") }}
              </v-btn>
              <v-btn
                color="error"
                text
                @click="deleteAccount"
                :loading="deletingAccount"
                :disabled="phrase !== requiredPhrase || !agree"
                large
              >
                {{ $translation.t("Delete Account") }}
              </v-btn>
            </div>
          </div>
        </template>
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import ThCard from "@/components/GeneralComponents/ThCard";
import dayjs from "dayjs";
import { mapState } from "vuex";
import rules from "@/utils/inputRules";

export default {
  name: "AccountInformation",
  components: { ThCard },
  data: () => ({
    rules,
    deleteAccountConfirmation: false,
    deletingAccount: false,
    phrase: "",
    agree: false,
  }),
  computed: {
    requiredPhrase() {
      return this.$translation.t("delete account");
    },
    createdAt() {
      return dayjs(this.clientCreatedAt).format("YYYY MM DD");
    },
    ...mapState({
      clientCreatedAt: (state) => state.client.created_at,
    }),
  },
  methods: {
    async deleteAccount() {
      this.deletingAccount = true;
      try {
        await this.$axios.delete("/api/v1/me");

        await this.$store.dispatch("logout");
      } catch (e) {
        this.$log.notify(e);
      }
      this.deletingAccount = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
