var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('th-card',{staticClass:"h-100"},[_c('template',{slot:"header"},[_c('h6',{domProps:{"innerHTML":_vm._s(_vm.$translation.t('Email Communications'))}}),_c('p',{staticClass:"body2",domProps:{"innerHTML":_vm._s(
          _vm.$translation.t('Control what information you want to get from us')
        )}})]),_c('template',{slot:"body"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-md-center th-py-16"},[_c('div',{staticClass:"flex-grow-1 max-w-450-md"},[_c('h6',{staticClass:"th-mb-8 th-mr-12 subtitle2 profile-details__item-title",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Transaction emails'))}}),_c('p',{staticClass:"caption text-neutral-dark",domProps:{"innerHTML":_vm._s(
              _vm.$translation.t(
                'These are the main emails. Transactional email is triggered by events, interactions you make by using TermsHub. You might receive payment confirmations, our policy updates, etc.'
              )
            )}})]),_c('div',{staticClass:"d-flex align-items-center th-mx-24-md th-my-12 th-my-0-md flex-grow-1"},[_c('img',{staticClass:"th-mr-8",attrs:{"src":require('@/assets/images/statuses/check-success.svg'),"height":"18","alt":"Info"}}),_c('span',{staticClass:"body2",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Enabled'))}})])]),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-md-center th-py-16 cursor-pointer",on:{"click":function($event){_vm.manageNewsletter = true}}},[_c('div',{staticClass:"flex-grow-1 max-w-450-md"},[_c('h6',{staticClass:"th-mb-8 th-mr-12 subtitle2 profile-details__item-title",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Newsletter'))}}),_c('p',{staticClass:"caption text-neutral-dark",domProps:{"innerHTML":_vm._s(
              _vm.$translation.t(
                'Find out first about TermsHub newest features. Stay up to date with latest regulations which might impact you business'
              )
            )}})]),_c('div',{staticClass:"d-flex align-items-center th-mx-24-md th-my-12 th-my-0-md flex-grow-1"},[_c('img',{staticClass:"th-mr-8",attrs:{"src":_vm.iconByStatus(_vm.subscribedToNewsletter),"alt":_vm.$translation.t(_vm.subscribedToNewsletter ? 'Enabled' : 'Disabled'),"width":"18"}}),_c('span',{staticClass:"body2",domProps:{"innerHTML":_vm._s(
              _vm.$translation.t(_vm.subscribedToNewsletter ? 'Enabled' : 'Disabled')
            )}})]),_c('div',{staticClass:"d-none d-md-block"},[_c('v-icon',{attrs:{"size":"24","color":"secondaryDark"}},[_vm._v(" mdi-chevron-right ")])],1)])])],2),_c('v-dialog',{attrs:{"max-width":"540","transition":"dialog-bottom-transition"},model:{value:(_vm.manageNewsletter),callback:function ($$v) {_vm.manageNewsletter=$$v},expression:"manageNewsletter"}},[_c('th-card',{attrs:{"body-classes":"th-p-40"}},[_c('template',{slot:"body"},[_c('h5',{staticClass:"th-mb-24",domProps:{"innerHTML":_vm._s(_vm.$translation.t('Update newsletter preferences'))}}),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-md-center th-mb-24"},[_c('v-switch',{attrs:{"color":"primary"},model:{value:(_vm.newsletterStatus),callback:function ($$v) {_vm.newsletterStatus=$$v},expression:"newsletterStatus"}}),_c('h6',{staticClass:"subtitle1",domProps:{"innerHTML":_vm._s(
              _vm.$translation.t('I want to receive email update from TermsHub')
            )}})],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.newsletterUpdating,"large":""},on:{"click":_vm.updateNewsletterPreferences}},[_vm._v(" "+_vm._s(_vm.$translation.t("Update preferences"))+" ")])],1)])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }