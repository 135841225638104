<template>
  <div>
    <template v-if="checkEmail">
      <h5
        class="th-mb-24 text-center"
        v-html="$translation.t('Great, check your inbox!')"
      ></h5>
      <p class="text-center">
        {{
          $translation.t(
            "We've just sent you a link to {email} to reset your password. Click on the link in the email and follow the instructions",
            { email }
          )
        }}
      </p>
      <v-btn
        color="secondaryDark"
        text
        small
        block
        :loading="resetting"
        class="mx-auto th-mt-24"
        v-show="showResendEmail"
        @click.native="resetPassword"
      >
        {{ $translation.t("Resend email") }}
      </v-btn>
    </template>
    <div v-else>
      <slot />
      <v-form ref="passwordReset">
        <v-text-field
          :label="$translation.t('Previous password')"
          :rules="[rules.required]"
          outlined
          type="password"
          id="oldPassword"
          name="oldPassword"
          v-model="oldPassword"
          class="mb-3"
          hide-details="auto"
        ></v-text-field>
        <v-text-field
          :label="$translation.t('Password')"
          :rules="[rules.required, rules.password8]"
          outlined
          id="password"
          name="password"
          type="password"
          v-model="password"
          class="mb-3"
          hide-details="auto"
        ></v-text-field>
        <v-text-field
          :label="$translation.t('Password Confirmation')"
          :rules="[rules.required, checkPasswordSame]"
          outlined
          id="passwordConfirmation"
          name="passwordConfirmation"
          type="password"
          v-model="passwordConfirmation"
          class="mb-3"
          hide-details="auto"
        ></v-text-field>
        <p v-if="error" class="text-danger th-my-12">{{ error }}</p>
        <div class="text-right th-my-16">
          <a href id="login_password_reset" @click.prevent="resetPassword">
            <small class="caption text-secondary">
              {{ $translation.t("Forgot password?") }}
            </small>
          </a>
        </div>
        <div class="text-right">
          <v-btn
            color="primary"
            :block="$vuetify.breakpoint.smAndDown"
            large
            @click="passwordReset"
            :loading="resetting"
          >
            {{ $translation.t("Change password") }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import { mapState } from "vuex";

export default {
  name: "PasswordChangeForm",
  data: () => ({
    rules,
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
    resetting: false,
    checkEmail: false,
    error: "",
    errorInputs: [],
    showResendEmail: false,
  }),
  computed: {
    ...mapState({
      email: (state) => state.client.email,
    }),
  },
  methods: {
    async passwordReset() {
      if (!this.$refs.passwordReset.validate()) return;

      this.resetting = true;

      try {
        await this.$axios.put("/api/v1/auth/password-change", {
          oldPassword: this.oldPassword,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        });

        this.$emit("success");
      } catch (e) {
        this.error = e.response.data.message;
        this.errorInputs = e.response.data.inputs || [];
        this.$log.notify(e);
      }

      this.resetting = false;
    },
    async resetPassword() {
      this.resetting = true;
      try {
        await this.$axios.post("/api/v1/auth/password-reset", {
          email: this.email,
        });
      } catch (e) {
        this.$log.notify(e);
      }

      this.checkEmail = true;
      this.resetting = false;

      setTimeout(() => {
        this.showResendEmail = true;
      }, 8000);
    },
    checkPasswordSame(value) {
      return value === this.password
        ? true
        : this.$translation.t("Passwords must match");
    },
  },
};
</script>

<style lang="scss" scoped></style>
